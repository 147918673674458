import React, { useState } from "react";
import { Table } from "antd";
import ExpandedDetails from "./ExpandedDetails";
import { PlusCircleOutlined } from "@ant-design/icons";
import WonTick from "../../../../assets/images/won-tick.png";
import LostTick from "../../../../assets/images/lost-tick.png";
import { useScreenWidth } from "../../../../utils/useScreenWidth";
import PremierLeagueIcon from "../../../../assets/images/premier_league.svg";
import {
  BorderedButton,
  CountTimer,
  SimpleFilledButton,
} from "../../../commonComponents";
import "./OngoingTable.scss";

const OngoingTable = ({ ongoingTableData, getOngoingGames }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandOnMobile, setExpandOnMobile] = useState({
    key: null,
    expand: false,
  });
  const [buttonType, setButtonType] = useState(0);
  const [expandedRowsKey, setExpandedRowsKey] = useState([]);
  const [isCopied, setIsCopied] = useState();
  const screenWidth = useScreenWidth();

  const expandedRowRender = () => {
    return (
      <ExpandedDetails
        expandedRowsKey={expandedRowKeys}
        buttonType={buttonType}
        getOngoingGames={getOngoingGames}
      />
    );
  };
  const handleMobileExpand = (key, value) => {
    setExpandOnMobile({
      key: key,
      expand: value,
    });
    const matchedData = ongoingTableData?.find((item) => item.key === key);
    setExpandedRowKeys(matchedData);
  };

  const handleExpandToggle = (key) => {
    const isRowExpanded = expandedRowsKey.includes(key);
    if (isRowExpanded) {
      setExpandedRowsKey(expandedRowsKey.filter((k) => k !== key));
      setExpandedRowKeys([]);
    } else {
      setExpandedRowsKey([...expandedRowsKey, key]);
      const matchedData = ongoingTableData?.find((item) => item.id === key);
      setExpandedRowKeys(matchedData);
    }
  };

  const copyToClipboard = async (url, gameId) => {
    const URL = window.location.origin;
    try {
      await navigator.clipboard.writeText(`${URL}/dashboard?private_token=${url}
      `);
      setIsCopied(gameId);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };
  const columns = [
    {
      title: "League",
      dataIndex: "league_name",
      render: (_, record) => {
        return (
          <>
            {!record.is_public && (
              <div className="ongoing-table-private-tag font-cairo-bold">
                Private
              </div>
            )}
            {record.is_public && record.is_turbo && (
              <div className="game-table-turbotag font-cairo-bold">Turbo</div>
            )}
            <div className="ongoing-table-competitions position-relative">
              <img
                src={
                  record.season_id == 23614
                    ? PremierLeagueIcon
                    : record.league_image
                }
                alt="League Icon"
                className="ongoing-table-competitions-image"
                style={{
                  width: record?.sport_id == 5 ? "14px" : "36px",
                  height: record?.sport_id == 5 ? "25px" : "36px",
                }}
              />
              <div>{record.league_name}</div>
            </div>
          </>
        );
      },
    },
    {
      title: "Entries Remaining",
      dataIndex: "joined_players",
      render: (_, record) => {
        return (
          <div className="ongoing-table-entries-cont">
            {record?.joined_players
              ?.toString()
              ?.split("")
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                  >
                    {item}
                  </div>
                );
              })}
            <div className="ongoing-table-entries-centerline"></div>
            {record.round_number > 1
              ? record?.max_players
                  ?.toString()
                  ?.split("")
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                      >
                        {item}
                      </div>
                    );
                  })
              : record?.joined_players
                  ?.toString()
                  ?.split("")
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                      >
                        {item}
                      </div>
                    );
                  })}
          </div>
        );
      },
    },
    {
      title: "Pot",
      dataIndex: "pot",
      render: (_, record) => {
        return <div>£{Math.floor(record.pot)}</div>;
      },
    },
    {
      title: "Round",
      dataIndex: "round_number",
    },
    {
      title: "My Selections",
      dataIndex: "teams",
      render: (_, record) => {
        return (
          <div>
            {record?.teams?.entry_1 ? (
              record?.teams?.entry_1.team_id == "" ? (
                <div
                  className="ongoing-table-actions-addentry"
                  key={record?.teams?.entry_1.team_id}
                  onClick={() =>
                    handleExpandToggle(record.key, record.is_joined)
                  }
                >
                  <PlusCircleOutlined />
                  <div className="font-cairo-bold">
                    Entry {record.is_turbo ? "" : 1}
                  </div>
                </div>
              ) : (
                <div
                  className="ongoing-table-selections"
                  key={record?.teams?.entry_1.team_id}
                >
                  <div style={{ width: "100%" }}>
                    <div className="ongoing-table-selections-self">
                      <img
                        src={record?.teams?.entry_1.team_image}
                        alt="Team Icon"
                        className="ongoing-table-selections-icon"
                      />
                      <div>{record?.teams?.entry_1.team_name} </div>
                    </div>
                    <div className="ongoing-table-selections-opponent">
                      vs {record?.teams?.entry_1.opponent?.name} &nbsp; &nbsp;
                      {record?.in_progress &&
                      !(record.teams.entry_1.status == "In Progress") ? (
                        <span className="white-color">
                          {record?.teams?.entry_1?.score} -&nbsp;
                          {record?.teams?.entry_1?.opponent?.score}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {record?.in_progress &&
                    (record?.teams?.entry_1?.status == "Lost" ? (
                      <>
                        <img
                          src={LostTick}
                          alt="Lost Red Icon"
                          className="result-icon"
                        />
                      </>
                    ) : record?.teams?.entry_1?.status == "Won" ? (
                      <>
                        <img
                          src={WonTick}
                          alt="Lost Red Icon"
                          className="result-icon"
                        />
                      </>
                    ) : null)}
                </div>
              )
            ) : null}
            {record?.teams?.entry_2 ? (
              record?.teams?.entry_2.team_id == "" ? (
                <>
                  <div
                    className="ongoing-table-actions-addentry"
                    key={record?.teams?.entry_2.team_id}
                    onClick={() =>
                      handleExpandToggle(record.key, record.is_joined)
                    }
                  >
                    <PlusCircleOutlined />
                    <div className="font-cairo-bold">Entry 2</div>
                  </div>
                </>
              ) : (
                <div
                  className="ongoing-table-selections"
                  key={record?.teams?.entry_2.team_id}
                >
                  <div style={{ width: "100%" }}>
                    <div className="ongoing-table-selections-self">
                      <img
                        src={record?.teams?.entry_2.team_image}
                        alt="Team Icon"
                        className="ongoing-table-selections-image"
                      />
                      <div>{record?.teams?.entry_2.team_name} </div>
                    </div>
                    <div className="ongoing-table-selections-opponent">
                      vs {record?.teams?.entry_2.opponent?.name} &nbsp; &nbsp;
                      {record?.in_progress &&
                      !(record.teams.entry_2.status == "In Progress") ? (
                        <span className="white-color">
                          {record?.teams?.entry_2?.score} -&nbsp;
                          {record?.teams?.entry_2?.opponent?.score}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {record?.in_progress &&
                    (record?.teams?.entry_2?.status == "Lost" ? (
                      <>
                        <img
                          src={LostTick}
                          alt="Lost Red Icon"
                          className="result-icon"
                        />
                      </>
                    ) : record?.teams?.entry_2?.status == "Won" ? (
                      <>
                        <img
                          src={WonTick}
                          alt="Lost Red Icon"
                          className="result-icon"
                        />
                      </>
                    ) : null)}
                </div>
              )
            ) : null}
            {record?.teams?.entry_3 ? (
              record?.teams?.entry_3?.team_id == "" ? (
                <div
                  className="ongoing-table-actions-addentry"
                  key={record?.teams?.entry_3?.team_id}
                  onClick={() =>
                    handleExpandToggle(record.key, record.is_joined)
                  }
                >
                  <PlusCircleOutlined />
                  <div className="font-cairo-bold">Entry 3</div>
                </div>
              ) : (
                <div
                  className="ongoing-table-selections"
                  key={record?.teams?.entry_3.team_id}
                >
                  <div style={{ width: "100%" }}>
                    <div className="ongoing-table-selections-self">
                      <img
                        src={record?.teams?.entry_3.team_image}
                        alt="Team Icon"
                        className="ongoing-table-selections-image"
                      />
                      <div>{record?.teams?.entry_3.team_name} </div>
                    </div>
                    <div className="ongoing-table-selections-opponent">
                      vs {record?.teams?.entry_3.opponent?.name} &nbsp; &nbsp;
                      {record?.in_progress &&
                      !(record.teams.entry_3.status == "In Progress") ? (
                        <span className="white-color">
                          {record?.teams?.entry_3?.score} -&nbsp;
                          {record?.teams?.entry_3?.opponent?.score}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {record?.in_progress &&
                    (record?.teams?.entry_3?.status == "Lost" ? (
                      <>
                        <img
                          src={LostTick}
                          alt="Lost Red Icon"
                          className="result-icon"
                        />
                      </>
                    ) : record?.teams?.entry_3?.status == "Won" ? (
                      <>
                        <img
                          src={WonTick}
                          alt="Lost Red Icon"
                          className="result-icon"
                        />
                      </>
                    ) : null)}
                </div>
              )
            ) : null}

            {record.is_turbo == false &&
            !record.in_progress &&
            Object.keys(record?.teams)?.length < 3 &&
            record.round_number == 1 &&
            record.sport_id == 3 ? (
              <div
                className="ongoing-table-actions-addteams text-start"
                onClick={() => {
                  handleExpandToggle(record.key, record.is_joined);
                  setButtonType("addTeam");
                }}
              >
                <PlusCircleOutlined className="ongoing-table-actions-icon" />
                <div className="font-cairo-bold">
                  {Object.keys(record?.teams)?.length === 1
                    ? "Add Entries"
                    : "Add Entry"}
                </div>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Timeline",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <div
            className={`${
              screenWidth >= 767
                ? "ongoing-table-actions"
                : "ongoing-table-mobile-actions"
            } `}
          >
            <CountTimer record={record.start_date} type={2} />
            {record.in_progress ? (
              <BorderedButton
                name={"View All Selections"}
                link={"/dashboard/allselections"}
                btnHeight={"45px"}
                btnClass={"ongoing-table-actions-btnview"}
                dataToSend={{ id: record.id, title: record.title }}
              />
            ) : !record.in_progress && record.select_teams ? (
              <SimpleFilledButton
                name={
                  Object.keys(record?.teams)?.length == 1
                    ? "Select Team"
                    : "Select Teams"
                }
                link={"#"}
                btnClass={"ongoing-table-actions-btnselect"}
                type={6}
                functionCall={() =>
                  handleExpandToggle(record.key, record.is_joined)
                }
              />
            ) : !record.in_progress ? (
              <div className="ongoing-table-sharecont">
                {!record.is_public && record.round_number == 1 ? (
                  <SimpleFilledButton
                    name={isCopied == record.id ? "Link Copied!" : "Share Link"}
                    link={"#"}
                    btnClass={"ongoing-table-actions-btnselect sharebtn"}
                    type={6}
                    functionCall={() =>
                      copyToClipboard(record.private_link, record.id)
                    }
                  />
                ) : null}
                <BorderedButton
                  name={(record.is_turbo == false && record.total_selected_teams > 1) ? "Edit Teams" : "Edit Team"}
                  link={"#"}
                  btnHeight={"45px"}
                  btnClass={"ongoing-table-actions-btnview"}
                  functionCall={() => {
                    handleExpandToggle(record.key, record.is_joined);
                    setButtonType("addTeam");
                  }}
                />
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <div className="ongoing-table position-relative">
      {screenWidth >= 767 ? (
        <Table
          columns={columns}
          dataSource={ongoingTableData}
          className="ongoing-table-cont font-cairo-normal"
          rowClassName="ongoing-table-row"
          rowkey={(record) => record.key}
          expandable={{
            expandedRowKeys: expandedRowsKey,
            expandedRowRender,
          }}
          pagination={false}
          loading={ongoingTableData ? false : true}
        />
      ) : (
        <div className="ongoing-mobile">
          {ongoingTableData?.map((item, index) => {
            return (
              <div className="ongoing-mobile-cont" key={index}>
                <div className="ongoing-mobile-cont-items">
                  {item.is_public == false && (
                    <div className="ongoing-table-private-tag font-cairo-bold">
                      Private
                    </div>
                  )}
                  <div className="ongoing-mobile-cont-items-title">League</div>
                  <div>{item.league_name}</div>
                </div>
                <div className="ongoing-mobile-cont-items">
                  <div className="ongoing-mobile-cont-items-title">
                    {item.round_number == 1 ? "Entries" : "Entries Remaining"}
                  </div>
                  {item.round_number == 1 ? (
                    item?.joined_players
                      ?.toString()
                      ?.split("")
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                          >
                            {item}
                          </div>
                        );
                      })
                  ) : (
                    <div className="ongoing-table-entries-cont">
                      {item?.joined_players
                        ?.toString()
                        ?.split("")
                        .map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                            >
                              {item}
                            </div>
                          );
                        })}
                      <div className="ongoing-table-entries-centerline"></div>{" "}
                      {item.round_number > 1
                        ? item?.max_players
                            ?.toString()
                            ?.split("")
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                                >
                                  {item}
                                </div>
                              );
                            })
                        : item?.joined_players
                            ?.toString()
                            ?.split("")
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                                >
                                  {item}
                                </div>
                              );
                            })}
                    </div>
                  )}
                </div>
                <div className="ongoing-mobile-cont-items">
                  <div className="ongoing-mobile-cont-items-title">Pot</div>
                  <div>£{Math.floor(item.pot)}</div>
                </div>
                <div className="ongoing-mobile-cont-items">
                  <div className="ongoing-mobile-cont-items-title">Round</div>
                  <div>{item.round_number}</div>
                </div>
                <div className="ongoing-mobile-cont-items">
                  <div className="ongoing-mobile-cont-items-title">
                    My Selection
                  </div>
                  <div className="ongoing-mobile-cont-items-title-teams">
                    {item?.teams?.entry_1 ? (
                      item?.teams?.entry_1.team_id == "" ? (
                        <div
                          className="ongoing-table-actions-addentry"
                          key={item?.teams?.entry_1.team_id}
                          onClick={() => {
                            handleMobileExpand(
                              item.key,
                              !expandOnMobile.expand
                            );
                            setButtonType("addTeam");
                          }}
                        >
                          <PlusCircleOutlined />
                          <div className="font-cairo-bold">
                            Entry {item.is_turbo ? "" : 1}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="ongoing-table-selections"
                          key={item?.teams?.entry_1.team_id}
                        >
                          <div style={{ width: "100%" }}>
                            <div className="ongoing-table-selections-self">
                              <img
                                src={item?.teams?.entry_1.team_image}
                                alt="Team Icon"
                                className="ongoing-table-selections-image"
                              />
                              <div>{item?.teams?.entry_1.team_name} </div>
                            </div>
                            <div className="ongoing-table-selections-opponent">
                              vs {item?.teams?.entry_1.opponent?.name} &nbsp;
                              &nbsp;
                              {item?.in_progress &&
                              !(item.teams.entry_1.status == "In Progress") ? (
                                <span className="white-color">
                                  {item?.teams?.entry_1?.score} -&nbsp;
                                  {item?.teams?.entry_1?.opponent?.score}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {item?.in_progress &&
                            (item?.teams?.entry_1?.status == "Lost" ? (
                              <>
                                <img
                                  src={LostTick}
                                  alt="Lost Red Icon"
                                  className="result-icon"
                                />
                              </>
                            ) : item?.teams?.entry_1?.status == "Won" ? (
                              <>
                                <img
                                  src={WonTick}
                                  alt="Lost Red Icon"
                                  className="result-icon"
                                />
                              </>
                            ) : null)}
                        </div>
                      )
                    ) : null}
                    {item?.teams?.entry_2 ? (
                      item?.teams?.entry_2.team_id == "" ? (
                        <div
                          className="ongoing-table-actions-addentry"
                          key={item?.teams?.entry_2.team_id}
                          onClick={() => {
                            handleMobileExpand(
                              item.key,
                              !expandOnMobile.expand
                            );
                            setButtonType("addTeam");
                          }}
                        >
                          <PlusCircleOutlined />
                          <div className="font-cairo-bold">Entry 2</div>
                        </div>
                      ) : (
                        <div
                          className="ongoing-table-selections"
                          key={item?.teams?.entry_2.team_id}
                        >
                          <div style={{ width: "100%" }}>
                            <div className="ongoing-table-selections-self">
                              <img
                                src={item?.teams?.entry_2.team_image}
                                alt="Team Icon"
                                className="ongoing-table-selections-image"
                              />
                              <div>{item?.teams?.entry_2.team_name} </div>
                            </div>
                            <div className="ongoing-table-selections-opponent">
                              vs {item?.teams?.entry_2.opponent?.name} &nbsp;
                              &nbsp;
                              {item?.in_progress &&
                              !(item.teams.entry_2.status == "In Progress") ? (
                                <span className="white-color">
                                  {item?.teams?.entry_2?.score} -&nbsp;
                                  {item?.teams?.entry_2?.opponent?.score}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {item?.in_progress &&
                            (item?.teams?.entry_2?.status == "Lost" ? (
                              <>
                                <img
                                  src={LostTick}
                                  alt="Lost Red Icon"
                                  className="result-icon"
                                />
                              </>
                            ) : item?.teams?.entry_2?.status == "Won" ? (
                              <>
                                <img
                                  src={WonTick}
                                  alt="Lost Red Icon"
                                  className="result-icon"
                                />
                              </>
                            ) : null)}
                        </div>
                      )
                    ) : null}
                    {item?.teams?.entry_3 ? (
                      item?.teams?.entry_3.team_id == "" ? (
                        <div
                          className="ongoing-table-actions-addentry"
                          key={item?.teams?.entry_3?.team_id}
                          onClick={() => {
                            handleMobileExpand(
                              item.key,
                              !expandOnMobile.expand
                            );
                            setButtonType("addTeam");
                          }}
                        >
                          <PlusCircleOutlined />
                          <div className="font-cairo-bold">Entry 3</div>
                        </div>
                      ) : (
                        <div
                          className="ongoing-table-selections"
                          key={item?.teams?.entry_3.team_id}
                        >
                          <div style={{ width: "100%" }}>
                            <div className="ongoing-table-selections-self">
                              <img
                                src={item?.teams?.entry_3.team_image}
                                alt="Team Icon"
                                className="ongoing-table-selections-image"
                              />
                              <div>{item?.teams?.entry_3.team_name} </div>
                            </div>
                            <div className="ongoing-table-selections-opponent">
                              vs {item?.teams?.entry_3.opponent?.name} &nbsp;
                              &nbsp;
                              {item?.in_progress &&
                              !(item.teams.entry_3.status == "In Progress") ? (
                                <span className="white-color">
                                  {item?.teams?.entry_3?.score} -&nbsp;
                                  {item?.teams?.entry_3?.opponent?.score}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {item?.in_progress &&
                            (item?.teams?.entry_3?.status == "Lost" ? (
                              <>
                                <img
                                  src={LostTick}
                                  alt="Lost Red Icon"
                                  className="result-icon"
                                />
                              </>
                            ) : item?.teams?.entry_3?.status == "Won" ? (
                              <>
                                <img
                                  src={WonTick}
                                  alt="Lost Red Icon"
                                  className="result-icon"
                                />
                              </>
                            ) : null)}
                        </div>
                      )
                    ) : null}

                    {item.is_turbo == false &&
                    !item.in_progress &&
                    Object.keys(item?.teams)?.length < 3 &&
                    item.round_number == 1 &&
                    item.sport_id == 3 ? (
                      <div
                        className="ongoing-table-actions-addteams text-start"
                        onClick={() => {
                          handleMobileExpand(item.key, !expandOnMobile.expand);
                          setButtonType("addTeam");
                        }}
                      >
                        <PlusCircleOutlined className="ongoing-table-actions-icon" />
                        <div className="font-cairo-bold">
                          {Object.keys(item?.teams)?.length === 1
                            ? "Add Entries"
                            : "Add Entry"}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="ongoing-mobile-cont-items alignself-baseline">
                  <div className="ongoing-mobile-cont-items-title">
                    Timeline
                  </div>
                  <div>
                    <CountTimer record={item.start_date} type={2} />
                    {item.in_progress ? (
                      <BorderedButton
                        name={"View All Selections"}
                        link={"/dashboard/allselections"}
                        btnHeight={"32px"}
                        btnClass={"ongoing-mobile-actions-button"}
                        dataToSend={{ id: item.id, title: item.title }}
                      />
                    ) : !item.in_progress && item.select_teams ? (
                      <SimpleFilledButton
                        name={
                          Object.keys(item?.teams)?.length == 1
                            ? "Select Team"
                            : "Select Teams"
                        }
                        link={"#"}
                        btnClass={"ongoing-mobile-actions-btnselect"}
                        type={6}
                        functionCall={() =>
                          handleMobileExpand(item.key, !expandOnMobile.expand)
                        }
                      />
                    ) : !item.in_progress ? (
                      <div className="ongoing-table-sharecont">
                        {!item.is_public && item.round_number == 1 ? (
                          <SimpleFilledButton
                            name={
                              isCopied == item.id
                                ? "Link Copied!"
                                : "Share Link"
                            }
                            link={"#"}
                            btnClass={
                              "ongoing-mobile-actions-btnselect sharebtn"
                            }
                            type={6}
                            functionCall={() =>
                              copyToClipboard(item.private_link, item.id)
                            }
                          />
                        ) : null}
                        <BorderedButton
                          name={
                            item.is_turbo == false   && item?.total_selected_teams >1 ?  "Edit Teams" : "Edit Team"
                          }
                          link={"#"}
                          btnHeight={"32px"}
                          btnClass={"ongoing-mobile-actions-button"}
                          functionCall={() => {
                            handleMobileExpand(
                              item.key,
                              !expandOnMobile.expand
                            );
                            setButtonType("addTeam");
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                {expandOnMobile.key == item.key && expandOnMobile.expand && (
                  <ExpandedDetails
                    expandedRowsKey={expandedRowKeys}
                    buttonType={buttonType}
                    getOngoingGames={getOngoingGames}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default OngoingTable;
