import React, { useEffect, useState } from "react";
import { useCountdown } from "../../../helpers/timer";
import "./PromoCounter.scss";

const PromoCounter = (props) => {
  const firstTimestamp = new Date(props.record).getTime();
  const secondTimestamp = new Date("2024-10-19T11:30:00+01:00").getTime();
  const [activeTimestamp, setActiveTimestamp] = useState(firstTimestamp);
  const [days, hours, minutes, seconds] = useCountdown(activeTimestamp);

  const checkTime = () => {
    const now = new Date().getTime() + 1000;
    // Check if the current time exceeds the firstTimestamp
    if (now >= firstTimestamp) {
      // If the first timestamp has expired, switch to the second timestamp
      setActiveTimestamp(secondTimestamp);
    }
    // If the current time exceeds the secondTimestamp, set to null
    if (now >= secondTimestamp && now >= firstTimestamp) {
      setActiveTimestamp(null);
    }
  };

  useEffect(() => {
    checkTime();
    const intervalId = setInterval(checkTime, 1000);
    return () => clearInterval(intervalId);
  }, [firstTimestamp, secondTimestamp]);

  return (
    <div className="banner-subtext">
      <div>Starts in </div>&nbsp;
      <>
        <span className="time-unit">
          {activeTimestamp !== null ? Math.max(days, 0) : 0}
        </span>{" "}
        days &nbsp;
        <span className="time-unit">
          {activeTimestamp !== null ? Math.max(hours, 0) : 0}
        </span>{" "}
        hrs &nbsp;
        <span className="time-unit">
          {activeTimestamp !== null ? Math.max(minutes, 0) : 0}
        </span>{" "}
        min &nbsp;
        <span className="time-unit">
          {activeTimestamp !== null ? Math.max(seconds, 0) : 0}
        </span>{" "}
        sec
      </>
    </div>
  );
};

export default PromoCounter;
